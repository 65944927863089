<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    round
    v-bind="$attrs"
    @open="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="select-popup-container">
      <div :class="['header', !showFilter && 'show-border']">
        <i
          class="iconclose iconfont iconfont-danchuangguanbi"
          @click="onClose"
        ></i>
        <h3 class="popup-title">{{ title }}</h3>
        <i
          class="iconsure iconfont iconfont-danchuangqueren"
          @click="onConfirm"
        ></i>
      </div>
      <div
        class="content"
        :style="{
          top: showFilter ? '54px' : '80px'
        }"
      >
        <van-search
          v-if="showFilter"
          v-model="filterStr"
          autofocus
          class="search-bar"
          placeholder="请输入"
          @input="filter"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div
          :class="{
            'select-popup-main': true,
            'no-filter-top': !showFilter
          }"
        >
          <div v-show="showList.length" class="select-popup-list">
            <div
              v-for="item in showList"
              :key="item.id"
              class="select-popup-list-item"
              :class="{ active: item.name === checkedValue }"
              @click="handlerClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <p v-show="!filterData.length" align="center" class="no-data">
            暂无数据
          </p>
        </div>
      </div>
    </section>
  </van-popup>
</template>

<script>
import uniqBy from "lodash/uniqBy";
import { getListTroubleList } from "@/api/improve/improve";
export default {
  name: "EntryTroubleName",
  props: {
    title: {
      type: String,
      default: "名称"
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    popupStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedValue: "",
      filterStr: "",
      filterData: [],
      troubleList: []
    };
  },
  computed: {
    showList() {
      const list = this.filterData.slice(0, this.max);
      return uniqBy(list, "name");
    }
  },
  methods: {
    async onOpen() {
      this.resetInnerStatue();
      await this.getTroubleList();
      this.filterData = [...this.troubleList];
    },
    async getTroubleList(name) {
      try {
        const notDisposeStatusStr = "0",
          size = 100;
        this.troubleList = await getListTroubleList({
          notDisposeStatusStr,
          name,
          size
        });
      } catch (e) {
        console.log(e);
      }
    },
    async filter(queryString) {
      const createFilter = item => {
        const value = item.name.toLowerCase();
        return value.indexOf(queryString.toLowerCase()) >= 0;
      };
      await this.getTroubleList(queryString);
      this.filterData = queryString
        ? this.troubleList.filter(createFilter)
        : this.troubleList;
    },
    handlerClick(row) {
      if (row.name === this.checkedValue) {
        this.onReset();
      } else {
        this.filterStr = this.checkedValue = row.name;
      }
    },
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm", this.filterStr || this.checkedValue || "");
      this.onClose();
    },
    resetInnerStatue() {
      this.checkedValue = "";
      this.filterStr = "";
    },
    onReset() {
      this.resetInnerStatue();
      this.$emit("reset");
    }
  }
};
</script>

<style lang="scss" scoped>
.choose-icon {
  font-size: 14px;
}
.choose-title {
  color: #2981ff;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}

.select-popup-container {
  position: relative;
  height: 100%;
}

.header {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  height: 54px;
  line-height: 54px;
  .iconclose,
  .iconsure {
    position: absolute;
    top: 0;
    width: 44px;
    height: 100%;
    text-align: center;
  }
  .iconclose {
    left: 0;
    color: #9496a3;
  }
  .iconsure {
    right: 0;
    color: #1676ff;
  }
  .popup-title {
    font-weight: bold;
    font-size: 16px;
    color: #1b1b4e;
  }
}
.show-border:after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  border-bottom: 0.5px solid #c7c9d0;
  transform: scaleY(0.5);
}

.content {
  position: absolute;
  /*top: 54px;*/
  /*top: 80px;*/
  bottom: 0;
  left: 0;
  right: 0;
  .search-bar {
    padding-top: 0;
  }
  .select-popup-main {
    position: absolute;
    top: 45px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .select-popup-list {
      &-item {
        // height: 44px;
        line-height: 24px;
        padding: 8px 16px;
        color: #2e2e4d;
        &.active {
          color: $--color-primary;
          background-color: rgba(22, 118, 255, 0.1);
        }
      }
    }
  }
  .no-filter-top {
    top: 0;
  }
  .no-data {
    padding-top: 80px;
    color: #aeb3c0;
    font-size: 14px;
  }
}
</style>
